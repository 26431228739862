import request from "@/plugin/request";
const _ = require("lodash");

export function reqLogin(data = {}) {
  return request({
    url: "/back_login",
    method: "POST",
    data,
  });
}

export function reqGetStore(data = {}) {
  return request({
    url: "/get_all_store",
    method: "POST",
    data,
  });
}

export function reqSetStore(data = {}) {
  return request({
    url: "/set_info",
    method: "POST",
    data,
  });
}


export function reqAddStore(data = {}) {
  return request({
    url: "/add_store",
    method: "POST",
    data,
  });
}


export function reqDelStore(data = {}) {
  return request({
    url: "/del_store",
    method: "POST",
    data,
  });
}



export function reqAddRecord(data = {}) {
  return request({
    url: "/set_record",
    method: "POST",
    data,
  });
}

export function reqGetRecord(data = {}) {
  return request({
    url: "/get_record",
    method: "POST",
    data,
  });
}

export function reqDelRecord(data = {}) {
  return request({
    url: "/del_record",
    method: "POST",
    data,
  });
}

export function reqGetToushu(data = {}) {
  return request({
    url: "/tousu_get",
    method: "POST",
    data,
  });
}

export function reqDelToushu(data = {}) {
  return request({
    url: "/del_ts",
    method: "POST",
    data,
  });
}

export function reqUpdateRecord(data = {}) {
  return request({
    url: "/updates",
    method: "POST",
    data,
  });
}




export function reqInsertJkz(data = {}) {
  return request({
    url: "/jkz_up",
    method: "POST",
    data,
  });
}
export function reqDelJkz(data = {}) {
  return request({
    url: "/jkz_del",
    method: "POST",
    data,
  });
}
export function reqGetJkz(data = {}) {
  return request({
    url: "/jkz_sel",
    method: "POST",
    data,
  });
}



