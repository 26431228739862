import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router';
import Root from '@/views/Root';
import menuRouters from '@/router/menu.router';
import LocalStorage from '@/utils/localstorage.util'
const mStorage = new LocalStorage(true)

export const routes = [
  {
    path: '/',
    name: 'Root',
    component: Root,
    children: menuRouters,
    redirect: '/qiye-manage',
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/Login/index.vue'),
  },
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.fullPath !== '/login') {
    let login = mStorage.get('token')
    if (!login) {
      next('/login')
    }
  }
  next();
});

export default router;
