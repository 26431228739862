import QiYeManage from "@/views/QiYeManage";

export default [
  {
    path: "/qiye-manage",
    name: "QiYeManage",
    component: QiYeManage,
  },
  {
    path: "/jcxq-manage",
    name: "JcxqManage",
    component: () => import("@/views/JcxqManage"),
  },
  {
    path: "/toushu-manage",
    name: "ToushuManage",
    component: () => import("@/views/ToushuManage"),
  },
  {
    path: "/jkz-detail",
    name: "JkzDetail",
    component: () => import("@/views/JkzDetail"),
  }
];



