<template>
  <router-view />
</template>

<style lang="scss">
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body,
#app {
  width: 100%;
  height: 100%;
}



.table-header,
.table-filter,
.table-list {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 20px;
}

.table-filter-content{
  display: flex;
  .table-filter-content-left{
    // flex: 1;
  }
  .table-filter-content-right{
    flex: 1;
  }
}

.w-180 {
  width: 180px;
}

.w-200 {
  width: 200px;
}
</style>
