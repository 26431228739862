<template>
  <!-- 搜索赛选 -->
  <!-- <div class="table-search"> -->
  <!-- <el-input
      v-model="state.tableSearchText"
      placeholder="请输入用户ID(按回车搜索)"
      clearable
      @keyup.enter="handleSearchUid"
    /> -->
  <!-- </div> -->
  <div class="table-header">
    <el-button type="primary" @click="handleInsertData">新增店铺</el-button>
  </div>

  <!-- 列表 -->
  <div class="table-list">
    <el-table
      :data="state.tableData"
      style="width: 100%"
      v-loading="state.tableLoading"
      @cell-dblclick="handleUpdateDataClick"
    >
      <el-table-column fixed prop="store_id" label="ID" width="200" />

      <el-table-column prop="store_name" label="名称" width="100">
        <template #default="scoped">
          <div class="el-table-template">
            <span>{{ scoped.row.store_name }}</span>
          </div>
        </template>
      </el-table-column>

      <el-table-column prop="qr_url" label="二维码" width="100">
        <template #default="scoped">
          <div class="el-table-template">
            <el-avatar :size="50" :src="scoped.row.qr_url" />
          </div>
        </template>
      </el-table-column>

      <el-table-column prop="avatar_url" label="头像" width="100">
        <template #default="scoped">
          <div class="el-table-template">
            <el-avatar :size="50" :src="scoped.row.avatar_url" />
          </div>
        </template>
      </el-table-column>

      <el-table-column prop="fxdj" label="风险等级" width="100">
        <template #default="scoped">
          <el-select
            v-model="scoped.row.fxdj"
            placeholder="请选择风险等级"
            @change="handleFxdjChange(scoped.row, scoped.column)"
          >
            <el-option
              v-for="item in state.fxdlList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </template>
      </el-table-column>

      <el-table-column prop="mydfs" label="满意度分数" width="100">
        <template #default="scoped">
          <el-select
            v-model="scoped.row.mydfs"
            placeholder="请选择满意度分数"
            @change="handleFxdjChange(scoped.row, scoped.column)"
          >
            <el-option
              v-for="item in state.mydfsList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </template>
      </el-table-column>

      <el-table-column prop="spjjxkz" label="食品经营许可证" width="100">
        <template #default="scoped">
          <div class="el-table-template">
            <el-avatar :size="50" :src="scoped.row.spjjxkz" shape="square" />
          </div>
        </template>
      </el-table-column>

      <el-table-column prop="yyzz" label="营业执照" width="100">
        <template #default="scoped">
          <div class="el-table-template">
            <el-avatar :size="50" :src="scoped.row.yyzz" shape="square" />
          </div>
        </template>
      </el-table-column>

      <el-table-column prop="all" label="企业人数" />

      <el-table-column prop="zc" label="健康证已上传人数" />
      <el-table-column prop="gq" label="健康证已过期人数" />
      <el-table-column prop="jjdq" label="健康证即将到期人数" />

      <!-- <el-table-column prop="khtg" label="考核通过人数" />
      <el-table-column prop="tbcs" label="自查填报次数" /> -->

      <el-table-column prop="jgdw" label="监管单位" />
      <el-table-column prop="zrr" label="责任人" />

      <el-table-column prop="cns" label="承诺书" width="100">
        <template #default="scoped">
          <div class="el-table-template">
            <el-avatar :size="50" :src="scoped.row.cns" shape="square" />
          </div>
        </template>
      </el-table-column>

      <el-table-column prop="pxhgz" label="就餐及后厨情况" width="100">
        <template #default="scoped">
          <div class="el-table-" style="display: flex">
            <el-button
              @click="handleUpdateDataFromMoreImage(scoped.row, scoped.column)"
              >查看详情</el-button
            >
            <!-- <el-avatar
              :size="50"
              :src="v.url"
              shape="square"
              v-for="v in scoped.row.pxhgz"
              :key="v.url"
              style="margin-right: 10px"
            /> -->
          </div>
        </template>
      </el-table-column>

      <el-table-column prop="jkz_list" label="从业人员健康证" width="100">
        <template #default="scoped">
          <div class="el-table-" style="display: flex">
            <el-button @click="handleGoJkzDetail(scoped.row)"
              >查看详情</el-button
            >
          </div>
        </template>
      </el-table-column>

      <el-table-column fixed="right" label="操作" width="120">
        <template #default="scoped">
          <div class="operations">
            <el-button
              link
              type="primary"
              size="small"
              @click="handleJcxqDetail(scoped.row)"
            >
              检查记录
            </el-button>
            <el-button
              link
              type="primary"
              size="small"
              @click="handleDeleteData(scoped.row)"
            >
              删除
            </el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
  </div>

  <!-- 分页 -->
  <div class="table-pagination">
    <h5>共{{ state.paginationTotal }}条数据</h5>
    <el-pagination
      background
      layout="prev, pager, next"
      :current-page="state.paginationPage"
      :total="state.paginationTotal"
      :disabled="state.paginationDisabled"
      @current-change="handleCurrentClick"
    />
  </div>

  <!-- 修改单张图片弹窗 -->
  <el-dialog
    v-model="state.updateOneImageDialogVisible"
    :title="state.updateOneImageFormData.title"
  >
    <el-form :model="state.updateOneImageFormData">
      <el-form-item
        :label="state.updateOneImageFormData.title"
        label-width="160px"
      >
        <el-upload
          class="avatar-uploader"
          drag
          action="https://jg.gfkjzjs.top/api/upload"
          :show-file-list="false"
          :on-success="handleUpdateOneImageSuccess"
        >
          <img
            v-if="state.updateOneImageFormData.url"
            :src="state.updateOneImageFormData.url"
            class="avatar"
          />
          <el-icon v-else class="avatar-uploader-icon">
            <Plus />
          </el-icon>
        </el-upload>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleUpdateOneImageClose"> 取消 </el-button>
        <el-button @click="handleUpdateOneImageClear"> 清空 </el-button>
        <el-button type="primary" @click="handleUpdateDataOneImage">
          修改
        </el-button>
      </span>
    </template>
  </el-dialog>

  <!-- 修改多张图片弹窗 -->
  <el-dialog
    v-model="state.updateMoreImageDialogVisible"
    :title="state.updateMoreImageFormData.title"
  >
    <el-form :model="state.updateMoreImageFormData">
      <el-form-item
        :label="state.updateMoreImageFormData.title"
        label-width="160px"
      >
        <el-upload
          drag
          action="https://jg.gfkjzjs.top/api/upload"
          multiple
          :file-list="state.updateMoreImageFormData.list"
          list-type="picture-card"
          :on-remove="handleUpdateMoreImageRemove"
          :on-success="handleUpdateMoreImageSuccess"
        >
          <el-icon>
            <Plus />
          </el-icon>
        </el-upload>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleUpdateMoreImageClose"> 取消 </el-button>
        <el-button @click="handleUpdateMoreImageClear"> 清空 </el-button>
        <el-button type="primary" @click="handleUpdateDataMoreImage">
          修改
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import { Plus } from "@element-plus/icons-vue";
import { ElMessage, ElMessageBox } from "element-plus";
import { reactive } from "vue";
import { reqGetStore, reqSetStore, reqAddStore, reqDelStore } from "@/api";
import { useRouter } from "vue-router";
const router = useRouter();
const _ = require("lodash");

//
const state = reactive({
  tableLoading: false,
  tableData: [],
  tableSearchText: "",

  paginationPage: 1,
  paginationTotal: 0,
  paginationDisabled: false,

  updateOneImageDialogVisible: false,
  updateOneImageFormData: {
    id: "",
    title: "",
    key: "",
    url: "",
  },

  updateMoreImageDialogVisible: false,
  updateMoreImageFormData: {
    id: "",
    title: "",
    key: "",
    list: [],
  },

  //风险等级
  fxdlList: [
    {
      id: 0,
      name: "A",
    },
    {
      id: 1,
      name: "B",
    },
    {
      id: 2,
      name: "C",
    },
    {
      id: 3,
      name: "D",
    },
  ],

  //可选的满意度分数
  mydfsList: [
    {
      id: 0,
      name: "0",
    },
    {
      id: 1,
      name: "1",
    },
    {
      id: 2,
      name: "2",
    },
    {
      id: 3,
      name: "3",
    },
    {
      id: 4,
      name: "4",
    },
    {
      id: 5,
      name: "5",
    },
  ],
});

const handlePagingQuery = async () => {
  state.tableData = [];
  state.tableLoading = true;
  state.paginationDisabled = true;
  try {
    const res = await reqGetStore({
      page: state.paginationPage,
      limit: 10,
    });
    state.paginationTotal = res.data.total_count;
    res.data.list.forEach((v) => {
      try {
        v.pxhgz = JSON.parse(v.pxhgz);
        v.pxhgz = v.pxhgz.map((j) => {
          return { url: j };
        });
      } catch (error) {
        console.log("解析失败", error);
        v.pxhgz = [];
      }
      try {
        v.jkz_list = JSON.parse(v.jkz_list);
        v.jkz_list = v.jkz_list.map((j) => {
          return { url: j };
        });
      } catch (error) {
        console.log("解析失败", error);
        v.jkz_list = [];
      }
      state.tableData.push(v);
    });
    console.log("总返回条数", res.data);
  } catch (error) {
    console.log(error);
  } finally {
    state.paginationDisabled = false;
    state.tableLoading = false;
  }
};

//点击页
const handleCurrentClick = function (i) {
  state.paginationPage = i;
  handlePagingQuery();
};

//搜索
const handleSearchUid = () => {
  state.paginationPage = 1;
  handlePagingQuery();
};

/*********************************************增删改查*********************************************/
const handleInsertData = () => {
  ElMessageBox.prompt(`请输入店铺名称`, `新增店铺`, {
    confirmButtonText: "新增",
    cancelButtonText: "取消",
    inputValue: "",
  }).then(async ({ value }) => {
    try {
      const res = await reqAddStore({
        store_name: value,
        avatar_url:
          "https://gd-hbimg.huaban.com/8a80141304aaf7e586d3d8a23ea27b70b7839c1e1b18-UpzXZb_fw658",
      });
      console.log("新增成功", res);
      ElMessage.success("新增成功");
      handlePagingQuery();
    } catch (error) {
      console.log(error);
    } finally {
    }
  });
};

const handleJcxqDetail = ({ store_id }) => {
  router.push(`/jcxq-manage?id=${store_id}`);
};

const handleGoJkzDetail = ({ store_id }) => {
  router.push(`/jkz-detail?id=${store_id}`);
};

const handleDeleteData = (row) => {
  const { store_id } = row;
  ElMessageBox.confirm("确认删除吗,删除后不可恢复?", "删除警告", {
    confirmButtonText: "确定",
    cancelButtonText: "取消",
    type: "warning",
  })
    .then(async () => {
      try {
        const res = await reqDelStore({
          store_id,
        });
        console.log("删除成功", res);
        ElMessage.success("删除成功");
        handlePagingQuery();
      } catch (error) {
        console.log(error);
        ElMessage.success("删除失败");
      } finally {
      }
    })
    .catch(() => {});
};

const handleUpdateOneImageSuccess = (e) => {
  state.updateOneImageFormData.url = e;
};

const handleUpdateMoreImageSuccess = (e) => {
  console.log("我只运行了一次",e);
  state.updateMoreImageFormData.list.push({ url: e })
};

const handleUpdateMoreImageRemove = (e) => {
  console.log(e);
  // state.updateMoreImageFormData.list.push({ url: e })
};

const handleUpdateOneImageClear = (e) => {
  state.updateOneImageFormData.url = "";
};
const handleUpdateMoreImageClear = (e) => {
  state.updateMoreImageFormData.list = [];
};

const handleUpdateOneImageClose = () => {
  state.updateOneImageFormData.id = "";
  state.updateOneImageFormData.title = "";
  state.updateOneImageFormData.key = "";
  state.updateOneImageFormData.url = "";
  state.updateOneImageDialogVisible = false;
};

const handleUpdateMoreImageClose = () => {
  state.updateMoreImageFormData.id = "";
  state.updateMoreImageFormData.title = "";
  state.updateMoreImageFormData.key = "";
  state.updateMoreImageFormData.list = [];
  state.updateMoreImageDialogVisible = false;
};

const handleFxdjChange = async (row, column) => {
  try {
    let obj = {};
    obj["store_id"] = row.store_id;
    obj[column.property] = row[column.property];
    const res = await reqSetStore(obj);
    ElMessage.success("修改成功", res);
    handlePagingQuery();
  } catch (error) {
    // console.log(error);
  }
};

const handleUpdateDataOneImage = async () => {
  try {
    let obj = {};
    obj["store_id"] = state.updateOneImageFormData.id;
    obj[state.updateOneImageFormData.key] = state.updateOneImageFormData.url;
    const res = await reqSetStore(obj);
    ElMessage.success("修改成功");
    handleUpdateOneImageClose();
    handlePagingQuery();
  } catch (error) {
    // console.log(error);
  }
};

const handleUpdateDataMoreImage = async () => {
  try {
    let obj = {};

    let list = [];
    list = state.updateMoreImageFormData.list.map((v) => {
      if (v.response) {
        return v.response;
      }
      return v.url;
    });

    console.log('list:',list);
    console.log('state.updateMoreImageFormData:',state.updateMoreImageFormData);

    // return

    obj["store_id"] = state.updateMoreImageFormData.id;
    obj[state.updateMoreImageFormData.key] = JSON.stringify(list);

    const res = await reqSetStore(obj);
    ElMessage.success("修改成功");
    handleUpdateMoreImageClose();
    handlePagingQuery();
  } catch (error) {
    // console.log(error);
  }
};

//双击修改文字
const handleUpdateDataFromText = (row, column) => {
  let _data = _.cloneDeep(row);
  ElMessageBox.prompt(`请输入${column.label}`, `修改${column.label}`, {
    confirmButtonText: "修改",
    cancelButtonText: "取消",
    inputValue: _data[column.property],
  }).then(async ({ value }) => {
    _data[column.property] = value;
    try {
      let obj = {};
      obj["store_id"] = _data["store_id"];
      obj[column.property] = _data[column.property];
      const res = await reqSetStore(obj);
      console.log("修改成功", res);
      ElMessage.success("修改成功");
      handlePagingQuery();
    } catch (error) {
      console.log(error);
    } finally {
    }
  });
};

//双击修改文字
const handleUpdateDataFromOneImage = (row, column) => {
  let _data = _.cloneDeep(row);
  state.updateOneImageFormData.id = _data["store_id"];
  state.updateOneImageFormData.title = column.label;
  state.updateOneImageFormData.key = column.property;
  state.updateOneImageFormData.url = _data[column.property];
  state.updateOneImageDialogVisible = true;
};

const handleUpdateDataFromMoreImage = (row, column) => {
  let _data = _.cloneDeep(row);
  state.updateMoreImageFormData.id = _data["store_id"];
  state.updateMoreImageFormData.title = column.label;
  state.updateMoreImageFormData.key = column.property;
  state.updateMoreImageFormData.list = _data[column.property];
  state.updateMoreImageDialogVisible = true;
};

// handleUpdateDataFromMoreImage

const handleUpdateDataClick = (row, column) => {
  switch (column.property) {
    case "store_id":
      break;
    case "qr_url":
      window.open(row[column.property]);
      break;
    case "avatar_url":
      handleUpdateDataFromOneImage(row, column);
      break;
    case "cns":
      handleUpdateDataFromOneImage(row, column);
      break;
    case "spjjxkz":
      handleUpdateDataFromOneImage(row, column);
      break;
    case "yyzz":
      handleUpdateDataFromOneImage(row, column);
      break;
    case "store_name":
      handleUpdateDataFromText(row, column);
      break;

    case "all":
      handleUpdateDataFromText(row, column);
      break;
    case "zc":
      // handleUpdateDataFromText(row, column);
      break;
    case "gq":
      // handleUpdateDataFromText(row, column);
      break;
    case "jjdq":
      // handleUpdateDataFromText(row, column);
      break;
    case "tbcs":
      handleUpdateDataFromText(row, column);
      break;
    case "khtg":
      handleUpdateDataFromText(row, column);
      break;
    case "jgdw":
      handleUpdateDataFromText(row, column);
      break;
    case "zrr":
      handleUpdateDataFromText(row, column);
      break;
    case "pxhgz":
      // handleUpdateDataFromMoreImage(row, column)
      break;
    case "jkz_list":
      // handleUpdateDataFromMoreImage(row, column)
      break;
    default:
      break;
  }
};

handlePagingQuery();
</script>

<style lang="scss" scoped>
.table-search {
  margin-top: 16px;
  width: 300px;
}

.table-list {
  margin-top: 16px;
  min-height: 440px;
}

.table-pagination {
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
  align-items: center;

  h5 {
    margin-right: 16px;
  }
}

.el-table-template {
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    display: -webkit-box;
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
}

.avatar-uploader .el-upload {
  border: 1px dashed var(--el-border-color);
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: var(--el-transition-duration-fast);
}

.avatar-uploader .el-upload:hover {
  border-color: var(--el-color-primary);
}

.el-icon.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  text-align: center;
}

.avatar {
  width: 200px;
  height: 200px;
}
</style>
