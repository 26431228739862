import {
  GoodsFilled,
  HomeFilled,
  Shop,
  List,
  QuestionFilled,
  Ticket,
  Promotion,
  WalletFilled,
  TrendCharts,
  UserFilled
} from "@element-plus/icons-vue";
import { reactive, markRaw } from "vue";

export default () =>
  reactive([
    {
      path: "/qiye-manage",
      title: "企业管理",
      show: true,
      icon: markRaw(HomeFilled),
    },
    {
      path: "/toushu-manage",
      title: "投诉管理",
      show: true,
      icon: markRaw(HomeFilled),
    },
  ]);
