import axios from "axios";
import LocalStorage from "@/utils/localstorage.util";
import AppConfig from "@/config/AppConfig";
import { ElMessage } from "element-plus";

let tokenStorage = new LocalStorage(true);

const request = axios.create({
  baseURL: AppConfig.APP_REQUEST_URL,
  timeout: 30000,
});

request.interceptors.request.use((config) => {
  config.headers = config.headers || {};
  // config.headers.token = tokenStorage.get('token') || '';
  // config.headers.sign = '1';
  return config;
});

request.interceptors.response.use(
  (res) => {
    let code = res.data.code;
    if (code !== "00000") {
      return Promise.reject(res.data);
    }
    return res.data;
  },
  (err) => {
    return Promise.reject(err);
  }
);

export default request;
